.simple-format_font-size-xx-large
  $font-size-xx-large()

.simple-format_font-size-small
  $font-size-small()

.simple-format_font-kazimir
  font-family: $font-kazimir

.simple-format_only-emoji
  $font-size-xx-large_no-mobile()
  line-height: 1
