.share-button
  $font-size-xx-small()
  color: $text-primary
  font-weight: bold
  text-transform: uppercase

.share-button_small

  .share-button__text
    display: inline-block

    @media $bp.mobile
      display: none

  .share-button__icon
    display: inline-block
    vertical-align: middle
    width: 24px
    height: @width
    margin-bottom: 2px
    fill: $text-secondary

.share-button_medium
  width: 48px
  height: @width
  padding: 0
  border-color: $base-line-secondary

  .share
    width: 32px
    height: 100%

  .share-button__container
    width: 100%
    height: 100%

  .share-button__text
    display: none

  .share-button__icon-svg
    width: 32px
    height: 100%
    fill: $text-accent

.share-button_big
  padding: 0

  .share
    width: 100%

  .share-button__text
    display: block

  .share-button__container
    padding: 8px 16px
