.card
  $card-light()
  $font-size-medium()

  @media $bp.mobile
    padding: 16px

  &__body
    position: relative
    word-wrap: break-word

    p
      padding: 0
      margin: -2px 0 8px

.card:not(:last-child):not(.card_page):not(.card_active):not(.card_thread)
  margin-bottom: 16px

.card_page
  padding: 24px 48px
  position: relative
  margin-bottom: 0

  @media $bp.mobile
    padding: 24px

  .card-header
    $font-size-small()

    @media $bp.mobile
      $font-size-x-small()

  .card__body p
    margin: 0 0 16px

  .quote
    margin: 8px 0 24px -48px

    @media $bp.mobile
      margin: 8px 0 24px -24px


// for cards of quotes or impressions nested inside post cards
.card_nested
  padding-right: 0
  padding-left: 16px
  border-left: 4px solid $base-line-secondary
  box-shadow: none

  .card__body
    color: $text-secondary

  .quote
    border-left: none
    color: $text-secondary
    margin-bottom: 0

  .book_card
    border-top: none

.card_active
  animation: highlight 2s
  margin-bottom: 0

@keyframes highlight
  50%
    box-shadow: 0 0 0 1px $base-line-accent

.card_thread
  box-shadow: none
  border-radius: 0
  margin-bottom: 0

  .card-footer
    border-top: none
    padding-top: 4px

  &:not(:last-child) .card-footer
    border-bottom: 1px solid rgba($base-line-secondary, 0.5)

  .card-header__useravatar
    flex-basis: 24px

.card

  .book
  .audiobook
  .comicbook
    padding: 16px 0
    border-top: 1px solid rgba($base-line-secondary, 0.5)
    box-shadow: none
    border-radius: 0

.card_nested

  .audiobook__description
  .book__description
  .comicbook__description
    margin: 0
