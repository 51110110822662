.card-header
  $font-size-x-small()
  display: flex
  align-items: center
  color: $text-primary
  line-height: 16px

  &__item:hover
    text-decoration: underline

  &__date
    margin-left: 0.5em

  &__item
    margin-left: 0.5em
    text-decoration: none
    color: $text-primary
    font-weight: bold

    &:hover
      text-decoration: underline

  &__username
    display: inline-block
    vertical-align: top
    margin: 0 0.5em 0 0
    max-width: 100%
    overflow-x: hidden
    text-overflow: ellipsis
    text-decoration: none
    color: $text-primary
    font-weight: bold

    &:hover
      text-decoration: underline

    &_pseudolink
      $link()
      border-bottom: none
      text-decoration: none

      &:hover
        text-decoration: none

  &__useravatar
    flex: 0 0 40px

    &_pseudolink
      cursor: pointer

  &__wrapper
    flex-grow: 1
    display: inline-block
    padding-left: 8px
    width: 1px

  &__action
    a
      text-decoration: none
      font-weight: bold

      &:hover
        text-decoration: underline

  &__action
  &__date
    text-decoration: none
    color: $text-secondary

  &__date
    &_pseudolink
      $link()
      border-bottom: none

      &:hover
      &:active
        border-bottom: none

  &__date span
    color: $text-primary

  &__date:not(&__date_active) span:hover
    text-decoration: underline

  &__date:before
    content: '• '

  &__date_active
    pointer-events: none

.card-header_nested
  padding-top: 4px
  padding-bottom: 12px

  .card-header__useravatar
    flex: 0

  .card-header__username
    color: $text-secondary
    font-weight: bold

  .card-header__date span
    color: $text-secondary
