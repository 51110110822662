.card-footer
  border-top: 1px solid rgba($base-line-secondary, 0.5)
  padding: 14px 0 1px

  &__counters
  &__actions
    $font-size-xx-small()
    list-style: none
    margin: 0
    padding: 0
    text-transform: uppercase
    font-weight: bold

  &__counters
    color: $text-secondary

  &__counter
    display: inline-block

    &_link
      $link-underline-on-hover()
      cursor: pointer

    &:not(:last-child)
      margin-right: 20px

  &__actions
    display: flex
    align-items: center

  &__action
    position: relative
    margin-right: 16px
    padding: 5px 0 5px 0
    cursor: pointer
    user-select: none
    background-repeat: no-repeat

    &_more
      margin-right: -8px
      padding-left: 0
      margin-left: auto

      html.no-flex-wrap &
        margin-left: 0

    &_like
      margin-left: -5px

    &_like-active .card-footer__icon
      animation: bounceIn 0.75s
      fill: $text-error

  &__icon
    display: inline-block
    width: 24px
    height: @width
    margin-bottom: 2px
    vertical-align: middle
    fill: $text-secondary

  &__icon-title
    display: inline

    @media $bp.mobile
      display: none

.card-footer__link
  color: $text-primary
  text-decoration: none

  &:hover, &:active
    color: $text-primary

@keyframes bounceIn
  0%, 100%, 20%, 40%, 60%, 80%
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
  0%
    opacity: 0
    transform: scale3d(0.3, 0.3, 0.3)
  20%
    transform: scale3d(1.1, 1.1, 1.1)
  40%
    transform: scale3d(0.9, 0.9, 0.9)
  60%
    opacity: 1
    transform: scale3d(1.03, 1.03, 1.03)
  80%
    transform: scale3d(0.97, 0.97, 0.97)
  100%
    opacity: 1
    transform: scale3d(1, 1, 1)
